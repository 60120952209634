body {
  margin: 0;
  padding: 0;
}

* {
  font-family: "Nunito";
  margin: 0;
  padding: 0;
}
